import { useContext } from "react";

import "./MsgCard.css";
import WebplaceContext from "../../../../store/webplace-context";

const MsgCard = () => {
  const placeCtx =
    useContext(WebplaceContext).homeShowcaseItems.showcase_items_message;
  const styleCtx =
    useContext(WebplaceContext).homeShowcaseItems.showcase_styles;

  return (
    <div
      className="message-card-wrapper"
    >
      <div className="message-card-inner-wrapper"
        style={{ backgroundColor: styleCtx.showcase_message_background }}>


        <div className="msg-info-wrapper">
          <div
            className="msg-title"
            style={{ color: styleCtx.showcase_message_font_color }}
            role="heading" aria-level="2"
          >
            {placeCtx.title}
          </div>
          <div
            className="msg-msg"
            style={{ color: styleCtx.showcase_message_font_color }}
          >
            {placeCtx.message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsgCard;
